<template>
  <div class="social-media-container">
    <span>FIND US ON</span>
    <a href="https://www.facebook.com/manestreetmarket" target="_blank">
      <i class="fa-brands fa-facebook"></i>
    </a>
    <a href="https://www.instagram.com/manestreetmarket" target="_blank">
      <i class="fa-brands fa-instagram"></i>
    </a>
    <a href="https://www.linkedin.com/in/manestreetmarket" target="_blank">
      <i class="fa-brands fa-linkedin"></i>
    </a>
  </div>
</template>