<template>
  <router-view/>
</template>

<script>
export default {
  beforeCreate() {
    this.$store.commit('initializeStore');
  }
}
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";
@import "assets/style";
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

* {
  font-family: 'Lora', serif !important;
}

.ant-message {
  z-index: 2000 !important;
}
</style>
