<template>
  <div class="header">
    <LoginDialog></LoginDialog>
    <div class="mobile-menu">
      <div class="close-icon" @click="closeMobileMenu()">
        <i class="fa-solid fa-xmark"></i>
      </div>
      <HeaderNav></HeaderNav>
      <hr>
      <div v-if="$store.state.token === null">
        <a class="nav-link" href="javascript:;" @click="openLoginModal()">Sign In</a>
        <a class="nav-link" href="javascript:;" @click="openRegisterModal()">Create Account</a>
      </div>
      <HeaderProfile :signOut="signOut" v-if="$store.state.token != null"></HeaderProfile>
      <HeaderSocials></HeaderSocials>
    </div>
    <div class="header-container">
      <router-link class="header-announcement" to="/trainer-package" v-if="!$store.state.is_trainer_package_subscriber">
        <span>NEW! {{ plans.trainer_package.name }} = unlimited sales horse & equipment listings for ${{ plans.trainer_package.prices.monthly.price }} a month. Click here for details: <i class="fa-solid fa-arrow-right" style="margin-left: 4px;"></i></span>
      </router-link>
      <div class="header-top-container">
        <div class="mobile-menu-hamburger" @click="openMobileMenu()">
          <i class="fa-solid fa-bars"></i>
        </div>
        <div class="header-top-left">
          <HeaderSocials></HeaderSocials>
        </div>
        <div class="header-top-middle">
          <router-link to="/">
            <img class="logo-image" src="../assets/img/logo.png" />
            <span>MANE STREET MARKET</span>
          </router-link>
        </div>
        <div class="header-top-right" v-if="$store.state.token === null">
          <a class="nav-link" href="javascript:;" @click="openLoginModal()">Sign In</a>
          <a class="nav-link action-button" href="javascript:;" @click="openRegisterModal()">Create Account</a>
        </div>
        <div class="header-top-right" v-if="$store.state.token != null">
          <a class="nav-link dropdown-toggle" id="auth-name" href="#" role="button" data-toggle="dropdown" style="display: flex; align-items: center; gap: 8px;">
            <span>{{$store.state.user.displayName}}</span>
            <i class="fa-solid fa-user"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <HeaderProfile :signOut="signOut"></HeaderProfile>
          </div>
        </div>
      </div>
      <div class="header-bottom-container">
        <div class="header-links-container">
          <HeaderNav></HeaderNav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import 'bootstrap/js/src/modal';
import 'bootstrap/js/src/dropdown';
import {auth} from '@/firebase';
import LoginDialog from "@/components/LoginDialog.vue";
import HeaderNav from '@/components/HeaderNav.vue';
import HeaderProfile from '@/components/HeaderProfile.vue';
import HeaderSocials from '@/components/HeaderSocials.vue';
import plans from '@/constants/plans';

export default {
  components: {
    LoginDialog,
    HeaderNav,
    HeaderProfile,
    HeaderSocials
  },
  data() {
    return {
      plans
    }
  },
  beforeUnmount() {
    this.closeMobileMenu();
  },
  methods: {
    async signOut() {
      try {
        await auth.signOut();
      } catch (error) {
        console.log(error);
      }
      this.$router.replace('/');
    },
    openLoginModal() {
      $('#loginModal').modal('show');
      $('#myTab a[href="#sign-in-content"]').tab('show')
    },
    openRegisterModal() {
      $('#loginModal').modal('show');
      $('#myTab a[href="#create-account-content"]').tab('show')
    },
    openMobileMenu() {
      $('.mobile-menu').css('display', 'flex');
      $('body').css('overflow', 'hidden');
    },
    closeMobileMenu() {
      $('.mobile-menu').css('display', 'none');
      $('body').css('overflow', 'auto');
    }
  }
}
</script>

<style lang="scss" scoped>
  .header-container {
    background-color: #051548;
    color: white;
  }

  .mobile-menu {
    display: none;
  }

  .header-announcement {
    background-color: #ffff59;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 4px 16px;
    color: #051548;
    cursor: pointer;
    text-align: center;
    color: #ff0000;
    font-weight: bold;
  }

  .header-top-container {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    padding: 16px 64px;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > .mobile-menu-hamburger {
      display: none;
    }
  }

  ::v-deep(.social-media-container) {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .header-top-container .header-top-left {
    justify-content: flex-start;
    padding-right: 32px;

    a {
      color: white;
      text-decoration: none;
    }

    ::v-deep(.social-media-container a) {
      color: white;
      width: 20px;
      height: 20px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .header-top-middle {
    border-left: 1px solid white;
    border-right: 1px solid white;

    a {
      color: white;
      text-decoration: none;
      display: flex;
      align-items: center;
      gap: 16px;

      span {
        font-size: 48px;
      }

      .logo-image {
        height: 48px;
      }
    }
  }

  .header-top-container .header-top-right {
    justify-content: flex-end;

    a {
      color: white;
    }

    .action-button {
      background: white;
      color: #051548 !important;
      font-weight: bold;
    }

    svg {
      width: 12px;
    }
  }

  .header-bottom-container {
    border-top: 1px solid white;
    padding: 8px 0;
  }

  ::v-deep(.header-links-container > div) {
    display: flex;
    gap: 32px;
    justify-content: center;
    padding: 0.5rem 1rem;
    
    a {
      color: white;
    }

    .nav-link {
      text-transform: uppercase;
      text-align: center;
      padding: 0;
    }
  }

  @media screen and (max-width: 1600px) {
    .header-top-container {
      grid-template-columns: minmax(250px, 1fr) 2.5fr minmax(250px, 1fr);
    }
  }

  @media screen and (max-width: 1360px) {
    .header-top-container {
      padding: 16px 32px;

      .header-top-middle {
        span {
          font-size: 40px;
        }

        a .logo-image {
          height: 42px;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .header-top-container {
      .header-top-middle {
        span {
          font-size: 32px;
        }

        a .logo-image {
          height: 36px;
        }
      }
    }

    ::v-deep(.header-links-container > div) {
      gap: 24px;
    }
  }

  @media screen and (max-width: 1050px) {
    .header-top-container {
      .header-top-middle {
        span {
          font-size: 24px;
        }

        a .logo-image {
          height: 32px;
        }
      }
    }

    ::v-deep(.header-links-container > div) {
      gap: 16px;
    }
  }

  @media screen and (max-width: 900px) {
    .header-top-container {
      padding: 16px;
      grid-template-columns: 1fr;
      position: relative;

      > .header-top-left, > .header-top-right {
        display: none;
      }

      > .mobile-menu-hamburger {
        display: flex;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 16px;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .header-bottom-container {
      display: none;
    }

    .header-top-middle {
      border: none;
    }

    .mobile-menu {
      flex-direction: column;
      position: absolute;
      z-index: 99;
      padding: 16px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: white;
      overflow: auto;

      .close-icon {
        position: fixed;
        top: 16px;
        right: 16px;
        font-size: 24px;
      }

      ::v-deep(a), ::v-deep(.dropdown-item) {
        padding: 4px 0;
        color: black;
      }

      ::v-deep(hr) {
        width: 100%;
        border-top: 1px solid #e9ecef;
        margin: 0.5rem 0;
      }

      ::v-deep(.social-media-container) {
        margin-top: 32px;

        a {
          width: 16px;
          height: 16px;
          padding: 0;
          align-content: center;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .header-top-container {
      .header-top-middle {
        span {
          font-size: 18px;
        }

        a {
          gap: 12px;
        }

        a .logo-image {
          height: 24px;
        }
      }
    }

    .header-announcement {
      font-size: 14px;
    }
  }
</style>