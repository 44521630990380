const basic = {
    id: process.env.VUE_APP_STRIPE_BASIC_ID,
    code: 'basic',
    name: 'Basic Plan',
    prices: {
        monthly: {
            id: process.env.VUE_APP_STRIPE_BASIC_PRICE_ID,
            price: 8.99
        }
    }
};

const regional = {
    id: process.env.VUE_APP_STRIPE_REGIONAL_ID,
    code: 'regional',
    name: 'Premium Plan',
    prices: {
        monthly: {
            id: process.env.VUE_APP_STRIPE_REGIONAL_PRICE_ID,
            price: 24.99
        }
    }
};

const national = {
    id: process.env.VUE_APP_STRIPE_NATIONAL_ID,
    code: 'national',
    name: 'App and Website Advertiser',
    prices: {
        monthly: {
            id: process.env.VUE_APP_STRIPE_NATIONAL_PRICE_ID,
            price: 49.99
        }
    }
};

const premier = {
    id: process.env.VUE_APP_STRIPE_PREMIER_ID,
    code: 'premier',
    name: 'Concierge Services',
    prices: {
        monthly: {
            id: process.env.VUE_APP_STRIPE_PREMIER_PRICE_ID,
            price: 149.99
        }
    }
};

const trainerPackage = {
    id: process.env.VUE_APP_STRIPE_TRAINER_PACKAGE_ID,
    code: 'trainer_package',
    name: 'Trainer/Breeder Package',
    prices: {
        monthly: {
            id: process.env.VUE_APP_STRIPE_TRAINER_PACKAGE_PRICE_ID,
            price: 39.99
        }
    }
};

module.exports = {
    'basic': basic,
    'regional': regional,
    'national': national,
    'premier': premier,
    'trainer_package': trainerPackage
};