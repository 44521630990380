import { createStore } from 'vuex'
import {db} from "@/firebase";
import firebase from 'firebase/app';

export default createStore({
  state: {
    token: null,
    user: null,
    profile: null,
    favorite_horses: [],
    favorite_services: [],
    favorite_equipments: [],
    is_trainer_package_subscriber: false,
    remembered_user: null
  },
  mutations: {
    initializeStore(state) {
      let rememberedUser = localStorage.getItem('rememberedUser');
      if (rememberedUser) {
        state.remembered_user = rememberedUser;
      }
    },
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      state.user = user;
    },
    setProfile(state, profile) {
      state.profile = profile;
    },
    setFavoriteHorses(state, favoriteHorses) {
      state.favorite_horses = favoriteHorses;
    },
    toggleFavoriteHorse(state, horseId) {
      if (state.favorite_horses.indexOf(horseId) > -1) {
        state.favorite_horses.slice(state.favorite_horses.indexOf(horseId), 1);
        db.collection('Users').doc(state.user.uid).update({
          favorite_horses: firebase.firestore.FieldValue.arrayRemove(horseId)
        })
      } else {
        state.favorite_horses.push(horseId);
        db.collection('Users').doc(state.user.uid).update({
          favorite_horses: firebase.firestore.FieldValue.arrayUnion(horseId)
        })
      }
    },
    setFavoriteServices(state, favoriteServices) {
      state.favorite_services = favoriteServices;
    },
    toggleFavoriteService(state, serviceId) {
      if (state.favorite_services.indexOf(serviceId) > -1) {
        state.favorite_services.slice(state.favorite_services.indexOf(serviceId), 1);
        db.collection('Users').doc(state.user.uid).update({
          favorite_services: firebase.firestore.FieldValue.arrayRemove(serviceId)
        })
      } else {
        state.favorite_services.push(serviceId);
        db.collection('Users').doc(state.user.uid).update({
          favorite_services: firebase.firestore.FieldValue.arrayUnion(serviceId)
        })
      }
    },
    setFavoriteEquipments(state, favoriteEquipments) {
      state.favorite_equipments = favoriteEquipments;
    },
    toggleFavoriteEquipment(state, equipmentId) {
      if (state.favorite_equipments.indexOf(equipmentId) > -1) {
        state.favorite_equipments.slice(state.favorite_equipments.indexOf(equipmentId), 1);
        db.collection('Users').doc(state.user.uid).update({
          favorite_equipments: firebase.firestore.FieldValue.arrayRemove(equipmentId)
        })
      } else {
        state.favorite_equipments.push(equipmentId);
        db.collection('Users').doc(state.user.uid).update({
          favorite_equipments: firebase.firestore.FieldValue.arrayUnion(equipmentId)
        })
      }
    },
    setIsTrainerPackageSubscriber(state, isSubscriber) {
      state.is_trainer_package_subscriber = isSubscriber;
    },
    rememberUser(state, email) {
      if (email != null) {
        localStorage.setItem('rememberedUser', email);
        state.remembered_user = email;
      } else {
        localStorage.removeItem('rememberedUser');
        state.remembered_user = null;
      }
    }
  },
  actions: {
    setToken(context, token) {
      context.commit('setToken', token);
    },
    setUser(context, user) {
      context.commit('setUser', user);
    },
    setProfile(context, profile) {
      context.commit('setProfile', profile);
      context.commit('setFavoriteHorses', profile && profile.favorite_horses ? profile.favorite_horses : []);
      context.commit('setFavoriteServices', profile && profile.favorite_services ? profile.favorite_services : []);
      context.commit('setFavoriteEquipments', profile && profile.favorite_equipments ? profile.favorite_equipments : []);
      if (profile && profile.trainer_package_subscription_id != null) {
        context.commit('setIsTrainerPackageSubscriber', true);
      } else {
        context.commit('setIsTrainerPackageSubscriber', false);
      }
    },
    setFavoriteHorses(context, favoriteHorses) {
      context.commit('setProfile', favoriteHorses);
    },
    toggleFavoriteHorse(context, horseId) {
      context.commit('toggleFavoriteHorse', horseId);
    },
    setFavoriteServices(context, favoriteServices) {
      context.commit('setProfile', favoriteServices);
    },
    toggleFavoriteService(context, serviceId) {
      context.commit('toggleFavoriteService', serviceId);
    },
    setFavoriteEquipments(context, favoriteEquipments) {
      context.commit('setProfile', favoriteEquipments);
    },
    toggleFavoriteEquipment(context, equipmentId) {
      context.commit('toggleFavoriteEquipment', equipmentId);
    },
    rememberUser(context, email) {
      context.commit('rememberUser', email);
    }
  },
  modules: {
  }
})
