<template>
  <div>
    <router-link class="nav-link" to="/">Home</router-link>
    <router-link class="nav-link" to="/search">Horses for Sale</router-link>
    <router-link class="nav-link" to="/save-listing">List a Horse for Sale</router-link>
    <router-link class="nav-link" to="/search-service-providers?type=Any">Equine Services</router-link>
    <router-link class="nav-link" to="/search-equipment?type=Any">Equipment</router-link>
    <router-link class="nav-link" to="/trainer-package">Trainer/Breeder Package</router-link>
    <router-link class="nav-link" :to="{ path: '/about', hash: '#press' }">In The Press</router-link>
    <router-link class="nav-link" to="/about">About</router-link>
    <router-link class="nav-link" to="/faq">FAQ's/Tutorials</router-link>
  </div>
</template>
