<template>
  <div class="main-content" :class="customClass">
    <slot></slot>
  </div>
  <div class="footer" v-if="showFooter">
    <div class="container">
      <div class="d-flex justify-content-between flex-wrap" style="column-gap: 24px;">
        <div>
          <ul class="list-unstyled">
            <li style="margin-bottom: 8px;"><router-link to="/plans">Listing / Advertising Plans and Pricing</router-link></li>
            <li style="margin-bottom: 8px;"><router-link to="/benefits-of-msm">Benefits of MSM</router-link></li>
            <li style="margin-bottom: 8px;"><router-link to="/my-listings" v-if="$store.state.token != null">Manage Listings</router-link></li>
            <li style="margin-bottom: 8px;"><a href="javascript:;" @click="openLoginModal()" v-if="$store.state.token == null">Manage Listings</a></li>
          </ul>
        </div>
        <div>
          <ul class="list-unstyled">
            <li style="margin-bottom: 8px;"><router-link to="/get-the-msm-app">Get the MSM App</router-link></li>
            <li style="margin-bottom: 8px;"><router-link to="/my-saved-filters">My Saved Searches</router-link></li>
          </ul>
        </div>
        <div>
          <ul class="list-unstyled">
            <li style="margin-bottom: 8px;"><router-link to="/terms-of-use">Terms of Use</router-link></li>
            <li style="margin-bottom: 8px;"><router-link to="/privacy-policy">Privacy Policy</router-link></li>
            <li style="margin-bottom: 8px;"><router-link to="/faq">FAQ's</router-link></li>
            <li style="margin-bottom: 8px;"><router-link to="/contact-us">Contact Us</router-link></li>
          </ul>
        </div>
        <div>
          <ul class="list-unstyled">
            <li style="margin-bottom: 8px;"><router-link to="/about">About</router-link></li>
            <li style="margin-bottom: 8px;"><a target="_blank" href="https://www.facebook.com/manestreetmarket/">Facebook</a></li>
            <li style="margin-bottom: 8px;"><a target="_blank" href="https://www.instagram.com/manestreetmarket/">Instagram</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  props: {
    'customClass': Object,
    'showFooter': {
      type: Boolean,
      default: true
    }
  },
  methods: {
    openLoginModal() {
      $('#loginModal').modal('show');
      $('#myTab a[href="#sign-in-content"]').tab('show')
    },
  }
}
</script>

<style lang="scss" scoped>
  .main-content {
    padding-bottom: 32px;
  }
  
  .footer {
    @media only screen and (max-width: 960px) {
      .d-flex div {
        flex-grow: 1;
        width: 40%;
      }
    }
  }
</style>