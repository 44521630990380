<template>
  <div>
    <router-link class="dropdown-item" to="/my-profile">Edit Profile</router-link>
    <router-link class="dropdown-item" to="/trainer-package">
      <div class="dropdown-item-container" v-if="!$store.state.is_trainer_package_subscriber">
        <div class="dropdown-item-title">
          <i class="fa-solid fa-crown" style="color: #FFD43B;"></i>
          <span>*NEW* {{ plans.trainer_package.name }}</span>
        </div>
        <span class="dropdown-item-small">Unlimited {{ plans.basic.name }} horse listings for ${{ plans.trainer_package.prices.monthly.price }} a month</span>
      </div>
      <div class="dropdown-item-container" v-else>
        <div class="dropdown-item-title">
          <i class="fa-solid fa-crown" style="color: #FFD43B;"></i>
          <span>Manage {{ plans.trainer_package.name }} subscription</span>
        </div>
      </div>
    </router-link>
    <router-link class="dropdown-item" to="/my-listings">
      <div class="dropdown-item-container">
        <span>Manage Horse Listings</span>
        <span class="dropdown-item-small">(Edit Listing Details, Update Payment Method, Change Plan, Delete Listing)</span>
      </div>
    </router-link>
    <router-link class="dropdown-item" to="/my-services">
      <div class="dropdown-item-container">
        <span>Manage Services Listings</span>
        <span class="dropdown-item-small">(Edit Listing Details, Update Payment Method, Change Plan, Delete Listing)</span>
      </div>
    </router-link>
    <router-link class="dropdown-item" to="/my-equipment">
      <div class="dropdown-item-container">
        <span>Manage Equipment Listings</span>
        <span class="dropdown-item-small">(Edit Listing Details, Update Payment Method, Change Plan, Delete Listing)</span>
      </div>
    </router-link>
    <router-link class="dropdown-item" to="/my-saved-horses">My Saved Searches - Horses</router-link>
    <router-link class="dropdown-item" to="/my-saved-services">My Saved Searches - Service Providers</router-link>
    <router-link class="dropdown-item" to="/my-saved-equipment">My Saved Searches - Equipment</router-link>
    <router-link class="dropdown-item" to="/my-ads">Manage Advertisements</router-link>
    <router-link class="dropdown-item" to="/my-saved-filters">My Filtered Searches</router-link>
    <div class="dropdown-divider"></div>
    <div class="dropdown-item profile-info">
      <i class="fa-solid fa-user"></i>
      <span>{{ $store.state.user.displayName }}</span>
    </div>
    <a class="dropdown-item" href="javascript:;" @click="signOut();">Sign out</a>
  </div>
</template>

<script>
import plans from '@/constants/plans';

export default {
  props: ['signOut'],
  data() {
    return {
      plans
    }
  }
}
</script>

<style lang="scss" scoped>
  .dropdown-item {
    color: #000 !important;
  }

  .dropdown-item-container {
    display: flex;
    flex-direction: column;
  }

  .dropdown-item-title {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .dropdown-item-small {
    font-size: 12px;
    white-space: wrap;
  }

  .profile-info {
    display: flex;
    gap: 8px;
    align-items: center;
    color: #555 !important;
    user-select: none;
    
    svg {
      font-size: 12px;
    }
  }
</style>